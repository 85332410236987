
import { Component, Vue } from 'vue-property-decorator'
import { TableData } from '@/types/intelligentMachine'

@Component({
  name: 'IntelligentMachineList',
  filters: {
    stateFilters (value: string) {
      return value === '2' ? '已结束' : value === '1' ? '正常'
        : ''
    }
  }
})
export default class extends Vue {
  private searchInfo = {
    projectName: '',
    smartMachineName: ''
  }

  private tableData: TableData[] =[]

  private page = 1
  private total = 0
  private size = 10
  private loading=false

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.theme.selectTopicActivitiesByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then(res => {
      if (res) {
        this.total = res.total || 0
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  add () {
    this.$router.push({ name: 'themeAdd' })
  }

  searchData () {
    this.page = 1
    this.getData()
  }

  detail (id: string) {
    this.$router.push({ name: 'themeDetail', params: { id: id } })
  }

  update (id: string) {
    this.$router.push({ name: 'themeUpdate', params: { id: id } })
  }

  deleteTheme (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.theme.deleteTopicActivities, { id }).then(() => {
          this.$message.success('删除成功')
          this.searchData()
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
